import React from "react";

export default function ContactsTable({ contacts }) {
  return (
    <section>
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold text-mantaropink">Contacts</h2>
      </div>

      <table className="lead-table">
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>E-Mail</th>
          <th>Phone</th>
        </tr>

        {contacts.map((contact) => (
          <>
            <tr>
              <td>{contact.first_name}</td>
              <td>{contact.last_name}</td>
              <td>{contact.emails && contact.emails[0]?.email ? contact.emails[0]?.email : ""}</td>
              <td>{contact.phones && contact.phones[0]?.phone ? contact.phones[0]?.phone : ""}</td>
            </tr>
            {contact.emails?.slice(1).map((extraEmail) => (
              <tr>
                <td></td>
                <td></td>
                <td>{extraEmail.email}</td>
                <td></td>
              </tr>
            ))}
            {contact.phones?.slice(1).map((extraPhone) => (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>{extraPhone.phone}</td>
              </tr>
            ))}
          </>
        ))}
      </table>
    </section>
  );
}
