import React from "react";
import { Link } from "react-router-dom";

export default function NavigationItem({ name, href, sub }) {
  return (
    <div>
      <Link to={href} className="block">
        <button className="button">{name}</button>
      </Link>
      <p className="text-center text-gray-400">{sub}</p>
    </div>
  );
}
