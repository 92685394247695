import React, { useState, useEffect } from "react";
import NavBar from "../general/NavBar";
import { gql, useMutation } from "@apollo/client";
import EnterSellerDetails from "../sellers/EnterSellerDetails";
import { useHistory } from "react-router-dom";
import LoadingScreen from "../general/LoadingScreen";

const ADD_SELLER_MUTATION = gql`
  mutation AddSellerMutation(
    $company_name: String!
    $status: String!
    $lead_type: String!
    $country: String!
    $state: String!
    $zip_code: String!
    $city: String!
    $street: String!
    $number: String!
    $first_name: String!
    $last_name: String!
    $email: String!
    $phone: String!
    $amazon_seller: AmazonSellerInput
    $brand_names: [String]
  ) {
    addSeller(
      company_name: $company_name
      status: $status
      lead_type: $lead_type
      country: $country
      state: $state
      city: $city
      zip_code: $zip_code
      street: $street
      number: $number
      first_name: $first_name
      last_name: $last_name
      email: $email
      phone: $phone
      amazon_seller: $amazon_seller
      brand_names: $brand_names
    )
  }
`;

export default function AddSeller({ match }) {
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    status: "Potential",
    lead_type: "personal",
  });
  const [address, setAddress] = useState({
    country: "DE",
    state: "",
    city: "",
    zip_code: "",
    street: "",
    number: "",
  });
  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",

    email_valid: false,
    phone_valid: false,
  });
  const [amazonSeller, setAmazonSeller] = useState({
    is_amazon_seller: false,
    amazon_seller_id: "",
  });
  const [brands, setBrands] = useState([{ name: "" }]);

  const [addSeller, { data, loading }] = useMutation(ADD_SELLER_MUTATION, {
    variables: {
      company_name: companyDetails.company_name,
      status: companyDetails.status,
      lead_type: companyDetails.lead_type,

      country: address.country,
      state: address.state,
      city: address.city,
      zip_code: address.zip_code,
      street: address.street,
      number: address.number,

      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email,
      phone: contact.phone,

      amazon_seller: {
        amazon_seller_id: amazonSeller.amazon_seller_id,
        amazon_seller_name: amazonSeller.amazon_seller_name,
      },

      brand_names: brands.filter((brand) => brand.name.length > 0).map((brand) => brand.name),
    },
  });

  const history = useHistory();

  if (data?.addSeller) {
    if (data.addSeller === "duplicate") {
      alert("Error! Found duplicate company name or email");
      data.addSeller = "";
    } else {
      history.push("/sellers/" + data?.addSeller);
    }
  }

  if (loading) return <LoadingScreen message="Adding seller..." />;

  // const mockHandler = () => {
  //   console.log(`companyDetails`, companyDetails)
  //   console.log(`address`, address)
  //   console.log(`contact`, contact)
  //   console.log(`amazonSeller`, amazonSeller)
  // }

  return (
    <div>
      <NavBar />
      <EnterSellerDetails
        editMode={false}
        companyDetails={companyDetails}
        setCompanyDetails={setCompanyDetails}
        address={address}
        setAddress={setAddress}
        contact={contact}
        setContact={setContact}
        amazonSeller={amazonSeller}
        setAmazonSeller={setAmazonSeller}
        brands={brands}
        setBrands={setBrands}
        handleSubmit={addSeller}
      />
    </div>
  );
}
