import React from "react";
import ProductDetails from "../products/ProductDetails";
import { gql, useQuery, useMutation } from "@apollo/client";
import LoadingAnimation from "../general/LoadingAnimation";

const GET_TINDER_BRAND = gql`
  query AllTinderProducts($brandId: ID!) {
    brand(id: $brandId) {
      mantaro_brand_id
      name
      allTinderProducts {
        asin
        mantaro_product_id
        amazon_product_id
        country_code
        name
        price
        currency
        number_of_ratings
        average_rating
        category_name
        translated_category_name
        bestseller_rank
        revenue_ltm {
          value
          time
        }
      }
    }
  }
`;

const HANDLE_TINDER_RESULT = gql`
  mutation handleTinderResult($match: Boolean!, $brand_id: Int!) {
    handleTinderResult(match: $match, brand_id: $brand_id)
  }
`;

let tinder_match;

export default function TinderBrand({ brand_id, handleNext }) {
  console.log("tinder brand id:", brand_id);
  const { loading, data } = useQuery(GET_TINDER_BRAND, {
    variables: { brandId: brand_id },
  });
  const [submitResult, { loading: submitLoading }] = useMutation(HANDLE_TINDER_RESULT, {
    variables: { match: tinder_match, brand_id: brand_id },
  });

  if (loading) return <LoadingAnimation message="Loading products..." />;
  if (submitLoading) return <LoadingAnimation message="Submitting result..." />;

  const brandName = data?.brand.name;
  const allTinderProducts = data?.brand?.allTinderProducts;

  console.log("brand in the tinder: ", data);
  const handleResult = (match) => {
    submitResult({
      variables: {
        match: match,
        brand_id: brand_id,
      },
    })
      .then(() => {
        handleNext();
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div>
      <div>
        <div className="my-4 items-center justify-between">
          <h1 className="text-2xl font-bold">{brandName}</h1>
        </div>
        <hr />
        <div className="overflow-y-auto h-80">
          {allTinderProducts && allTinderProducts.length > 0 ? (
            allTinderProducts.map((product) => (
              <ProductDetails key={product.amazon_product_id} product={product} />
            ))
          ) : (
            <div className="flex my-4 items-center justify-between">
              <p>No products found for this brand...</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <button
          onClick={() => {
            handleResult(false);
          }}
          className="w-1/3 text-white text-5xl font-bold p-6 rounded-lg bg-red-600 hover:bg-red-700"
        >
          No
        </button>
        <button
          onClick={() => {
            handleResult(true);
          }}
          className="w-1/3 text-white text-5xl font-bold p-6 rounded-lg bg-green-500 hover:bg-green-400"
        >
          Yes
        </button>
      </div>
    </div>
  );
}
