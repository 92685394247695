import React from "react";
import { Link } from "react-router-dom";

export default function BrandsSmallTable({ brands, setNewBrand, editMode, setBrands }) {
  return (
    <section>
      <h2 className="text-2xl font-bold text-mantaropink">Brands</h2>
      <table className="lead-table">
        <tr>
          <th>Mantaro Brand ID</th>
          <th>Brand name</th>
          <th>Platform</th>
        </tr>
        {editMode
          ? brands.map((brand, index) => (
              <tr>
                <td>{brand.brand_id}</td>
                <td>
                  <input
                    type="text"
                    value={brand.name}
                    onChange={(e) => {
                      let newBrands = [...brands];
                      newBrands[index] = { name: e.target.value, brand_id: brand.brand_id };
                      setBrands(newBrands);
                    }}
                  />
                </td>
                <td>{brand.platform}</td>
              </tr>
            ))
          : brands.map((brand) => (
              <tr>
                <td>{brand.brand_id}</td>
                <td>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={{ pathname: "/brands/" + brand.brand_id }}
                  >
                    {brand.name}
                  </Link>
                </td>
                <td>{brand.platform}</td>
              </tr>
            ))}
        {editMode ? (
          <tr>
            <td></td>
            <td>
              <input
                type="text"
                placeholder="new brand name"
                onChange={(e) => setNewBrand({ name: e.target.value })}
              />
            </td>
          </tr>
        ) : (
          ""
        )}
      </table>
      <hr className="w-full mx-auto" />
    </section>
  );
}
