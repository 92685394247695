import React from "react";
import NavBar from "../general/NavBar";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import LoadingScreen from "../general/LoadingScreen";
import SellerBox from "../sellers/SellerBox";
import { generateBrandURL, generatePictureURL, generateProductURL } from "../../utils/urlGenerator";

const BRAND_QUERY = gql`
  query brand($brand_id: ID!) {
    brand(id: $brand_id) {
      mantaro_brand_id
      name
      revenueLTM {
        time
        value
      }
      amazon_products {
        asin
        marketplaces {
          amazon_marketplace_id
          country_code
          name
          price
          currency
          number_of_ratings
          number_of_ratings
          average_rating
          translated_category_name
          bestseller_rank
          sales_ltm {
            amazon_product_id
            sales {
              value
              time
            }
          }
        }
      }
      mantaro_seller {
        mantaro_seller_id
        company_name
        amazon_seller {
          amazon_seller_id
        }
      }
    }
  }
`;

const mapProduct = (product) => {
  return {
    name: product.marketplaces[0].name,
    number_of_ratings: product.marketplaces[0].number_of_ratings,
    average_rating: product.marketplaces[0].average_rating,
    translated_category_name: product.marketplaces[0].translated_category_name,
    bestseller_rank: product.marketplaces[0].bestseller_rank,
    price: product.marketplaces[0].price,
    asin: product.asin,
    country_code: product.marketplaces[0].country_code,
    revenue: product.marketplaces[0].sales_ltm
      ? Math.round(
          (product.marketplaces[0].sales_ltm.sales.reduce((n, { value }) => n + value, 0) +
            Number.EPSILON) *
            100
        ) / 100
      : 0,
    lastDate: product.marketplaces[0].sales_ltm
      ? new Date(
          product.marketplaces[0].sales_ltm.sales.reduce((a, b) => (a.time > b.time ? a : b)).time
        ).toLocaleDateString()
      : new Date().toLocaleDateString(),
  };
};

export default function BrandView({ match }) {
  const brand_id = parseInt(match.params.brand_id);

  const { loading, error, data } = useQuery(BRAND_QUERY, {
    variables: {
      brand_id: brand_id,
    },
  });

  if (loading) return <LoadingScreen message="Loading brand" />;
  if (error) return <p>Error {error.message}</p>;
  const brand = data?.brand;

  const getProductLTMOfBrand = () => {
    return brand.revenueLTM ? Math.round((brand.revenueLTM.value + Number.EPSILON) * 100) / 100 : 0;
  };

  const getLastPurchaseDateOfBrand = () => {
    let lastDate = brand.revenueLTM ? new Date(brand.revenueLTM.time) : new Date();
    lastDate = lastDate.toLocaleDateString();
    return lastDate;
  };

  return (
    <div className="w-full">
      <NavBar />
      <div className="container mt-8">
        <section className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Brand: {brand.name}</h1>
          <button>
            <Link
              target="_blank"
              rel="noopener noreferrer external"
              to={{
                pathname: generateBrandURL(
                  brand.amazon_products[0]?.marketplaces[0]?.country_code,
                  brand.mantaro_seller.amazon_seller.amazon_seller_id,
                  brand.amazon_products[0]?.marketplaces[0]?.amazon_marketplace_id,
                  brand.name
                ),
              }}
            >
              <img src="../amazon_link.svg" />
            </Link>
          </button>
        </section>
        <hr />
        <section className="mt-6">
          <SellerBox
            mantaro_seller_id={brand.mantaro_seller.mantaro_seller_id}
            company_name={brand.mantaro_seller.company_name}
            marketplace_id={brand.amazon_products[0]?.marketplaces[0]?.amazon_marketplace_id}
            amazon_seller_id={brand.mantaro_seller.amazon_seller.amazon_seller_id}
          />
        </section>
        <section className="mt-6">
          <h3 className="text-mantaropink uppercase font-bold text-lg">Brand Sales Data</h3>
          <p>
            Revenue LTM:{" "}
            {getProductLTMOfBrand() + " EUR " + " (as of " + getLastPurchaseDateOfBrand() + ")"}
          </p>
        </section>
        <section className="mt-6">
          <h3 className="text-mantaropink uppercase font-bold text-lg mb-4">Products</h3>
          {brand.amazon_products && brand.amazon_products.length > 0 ? (
            <table className="table-fixed border-separate">
              <thead>
                <tr>
                  <th className="border px-8 py-4 text-mantaropink">Name</th>
                  <th className="border px-8 py-4 text-mantaropink">Image</th>
                  <th className="border px-8 py-4 text-mantaropink">Avg Rating</th>
                  <th className="border px-8 py-4 text-mantaropink">Total Reviews</th>
                  <th className="border px-8 py-4 text-mantaropink">Category</th>
                  <th className="border px-8 py-4 text-mantaropink">Link</th>
                </tr>
              </thead>
              <tbody>
                {brand.amazon_products
                  .map((p) => mapProduct(p))
                  .map((p) => (
                    <tr>
                      <td className="border px-8 py-4">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={{ pathname: "/products/" + p.asin }}
                        >
                          {p.name}
                        </Link>
                      </td>
                      <td className="border px-8 py-4">
                        <img src={generatePictureURL(p.asin, p.country_code)} width="125" />
                      </td>
                      <td className="border px-8 py-4 justify-center items-center">
                        {p.average_rating}
                      </td>
                      <td className="border px-8 py-4">{p.number_of_ratings}</td>
                      <td className="border px-8 py-4">{p.translated_category_name}</td>
                      <td className="border px-8 py-4">
                        {
                          <button>
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              to={{ pathname: generateProductURL(p.asin, p.country_code) }}
                            >
                              <img src="../amazon_link.svg" width="125" />
                            </Link>
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <p>No products found...</p>
          )}
        </section>
      </div>
    </div>
  );
}
