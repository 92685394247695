export const generateSellerURL = (marketplace_id, amazon_seller_id) => {
  return `https://amazon.de/sp?marketplaceID=${marketplace_id}&seller=${amazon_seller_id}`;
};

export const generateBrandURL = (country_code, amazon_seller_id, marketplace_id, brand_name) => {
  const prepared_brand_name = brand_name.replaceAll(" ", "+");
  return `https://www.amazon.${generateURLDomain(
    country_code
  )}/s?i=merchant-items&me=${amazon_seller_id}&rh=p_4%3A${prepared_brand_name}&dc&marketplaceID=${marketplace_id}`;
};

export const generateProductURL = (asin, country_code) => {
  return `https://amazon.${generateURLDomain(country_code)}/dp/${asin}`;
};

export const generatePictureURL = (asin, country_code) => {
  return `https://ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=${country_code}&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=AC_SL500`;
};

const generateURLDomain = (country_code) => {
  country_code = country_code ? country_code.toLowerCase() : "de";
  switch (country_code) {
    case "gb":
      country_code = "co.uk";
      break;
    case "us":
      country_code = "com";
      break;
    default:
      break;
  }
  return country_code;
};
