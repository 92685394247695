import React, { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";

const GET_FILTERED_TINDER_BRANDIDS = gql`
  query FilterQuery(
    $rating: Float!
    $ratingOperator: String!
    $review: Float!
    $reviewOperator: String!
  ) {
    getFilteredTinderBrandIDs(
      rating: $rating
      ratingOperator: $ratingOperator
      review: $review
      reviewOperator: $reviewOperator
    )
  }
`;

export const Comparison = {
  lt: {
    value: "<",
    desc: "less than",
  },
  lte: {
    value: "<=",
    desc: "less than or equal to",
  },
  eq: {
    value: "=",
    desc: "equal to",
  },
  gt: {
    value: ">",
    desc: "greater than",
  },
  gte: {
    value: ">=",
    desc: "greater than or equal to",
  },
  neq: {
    value: "!=",
    desc: "not equal to",
  },
};

export default function LeadFilter({ filterBrands, allBrandIDsArr }) {
  const [state, setState] = useState({
    rating: "5",
    ratingOperator: Comparison.lte.value,
    review: "0",
    reviewOperator: Comparison.gte.value,
  });
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [getFilteredBrandIDs] = useLazyQuery(GET_FILTERED_TINDER_BRANDIDS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      filterBrands(data?.getFilteredTinderBrandIDs);
    },
    onError: (error) => {
      console.log(JSON.stringify(error, null, 2));
    },
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const validateInput = () => {
    let validRating = false;
    let validReview = false;

    if (state.rating && state.rating >= 0 && state.rating <= 5) {
      setErrorMsg("");
      setState({
        ...state,
        [rating]: parseFloat(state.rating),
      });
      validRating = true;
    } else {
      setErrorMsg("Rating value is not in the correct interval of [0-5]");
      return false;
    }

    if (state.review && state.review >= 0) {
      setErrorMsg("");
      setState({
        ...state,
        [review]: parseFloat(state.review),
      });
      validReview = true;
    } else {
      setErrorMsg("Total number of reviews must be a positive number");
      return false;
    }

    return validReview && validRating;
  };

  const filter = () => {
    if (validateInput()) {
      setHasError(false);
      getFilteredBrandIDs({
        variables: {
          rating: parseFloat(state.rating),
          ratingOperator: state.ratingOperator,
          review: parseFloat(state.review),
          reviewOperator: state.reviewOperator,
        },
      });
    } else {
      setHasError(true);
    }
  };

  const reset = () => {
    setHasError(false);
    setState({
      rating: "5",
      ratingOperator: Comparison.lte.value,
      review: "0",
      reviewOperator: Comparison.gte.value,
    });
    filterBrands(allBrandIDsArr);
  };

  const ratingFilter = (
    <div className="p-2">
      <label htmlFor="avg_rating" className="block text-sm font-bold text-gray-700">
        Average rating
      </label>
      <div className="mt-1 rounded-md shadow-sm flex">
        <div className="items-center">
          <label htmlFor="ratingOperator" className="sr-only">
            Condition
          </label>
          <select
            id="ratingOperator"
            name="ratingOperator"
            value={state.ratingOperator}
            onChange={handleChange}
            className="border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            {Object.keys(Comparison).map((key) => (
              <option key={Comparison[key].value} value={Comparison[key].value}>
                {Comparison[key].desc}
              </option>
            ))}
          </select>
        </div>
        <input
          type="number"
          name="rating"
          id="rating"
          value={state.rating}
          onChange={handleChange}
          placeholder="4.3"
        />
      </div>
    </div>
  );

  const reviewFilter = (
    <div className="p-2">
      <label htmlFor="num_of_reviews" className="block text-sm font-bold text-gray-700">
        Total reviews
      </label>
      <div className="mt-1 rounded-md shadow-sm flex">
        <div className="items-center">
          <label htmlFor="reviewOperator" className="sr-only">
            Condition
          </label>
          <select
            id="reviewOperator"
            name="reviewOperator"
            value={state.reviewOperator}
            onChange={handleChange}
            className="border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
          >
            {Object.keys(Comparison).map((key) => (
              <option key={Comparison[key].value} value={Comparison[key].value}>
                {Comparison[key].desc}
              </option>
            ))}
          </select>
        </div>
        <input
          type="number"
          name="review"
          id="review"
          value={state.review}
          onChange={handleChange}
          placeholder="200"
        />
      </div>
    </div>
  );

  const filterButton = (
    <button onClick={() => filter()} className="button-reverse">
      Filter
    </button>
  );

  const resetButton = (
    <button onClick={() => reset()} className="button-reverse">
      Reset
    </button>
  );

  const errorDisplay = (
    <div
      className="bg-red-100 border-t border-b border-red-500 text-black-700 px-2 py-1"
      role="alert"
    >
      <p className="text-sm">Filter parameters are not correct: {errorMsg}</p>
    </div>
  );
  return (
    <div>
      <div className="flex items-center ...">
        {ratingFilter}
        {reviewFilter}
        {filterButton}
        {resetButton}
      </div>
      {hasError && <div>{errorDisplay}</div>}
    </div>
  );
}
