import React from "react";
import { Link } from "react-router-dom";
import { generatePictureURL, generateProductURL } from "../../utils/urlGenerator";
import DetailItem from "../general/DetailItem";

export default function ProductGeneralBox({ product }) {
  const getImagesOfProduct = () => {
    return (
      <div className="p-4 pt-1 flex items-center justify-between">
        <img
          alt=""
          src={generatePictureURL(product.asin, product.marketplaces[0].country_code)}
          width="225"
        />
      </div>
    );
  };

  return (
    <div className="shadow-md hover:shadow-lg duration-150 w-full">
      <div className="p-4 pt-1 flex items-center justify-between">
        <h3 className="text-mantaropink uppercase font-bold text-lg">General</h3>
        <button>
          <Link
            target="_blank"
            rel="noopener noreferrer external"
            to={{
              pathname: generateProductURL(product.asin, product.marketplaces[0].country_code),
            }}
          >
            <img src="../amazon_link.svg" />
          </Link>
        </button>
      </div>
      <section className="justify-between flex w-full">
        <div className="mt-4 w-1/2">
          <DetailItem
            cssProperty="p-4 pt-1 items-center justify-between"
            name="PRODUCT NAME"
            value={product.marketplaces[0].name}
          />
        </div>
        <div className="mt-4 w-1/2">
          <DetailItem
            cssProperty="p-4 pt-1 items-center justify-between"
            name="IMAGES"
            value={getImagesOfProduct()}
          />
        </div>
      </section>
      <section className="justify-between w-full">
        {product.marketplaces && product.marketplaces.length > 0 ? (
          <>
            <div className="p-4 pt-1 flex items-center justify-between">
              <h3 className="text-lg text-mantaropink">Product's Ratings:</h3>
            </div>
            {product.marketplaces.map((m) => (
              <div className="flex w-full">
                <DetailItem
                  cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                  name="MARKETPLACE COUNTRY"
                  value={m.country_code}
                />
                <DetailItem
                  cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                  name="NUMBER OF RATINGS"
                  value={m.number_of_ratings}
                />
                <DetailItem
                  cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                  name="AVERAGE PRODUCT RATING"
                  value={m.average_rating}
                />
                <DetailItem
                  cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                  name="BEST SELLER RANK"
                  value={m.bestseller_rank}
                />
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
