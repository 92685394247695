import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { setContext } from "@apollo/client/link/context";

import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });

// const token = localStorage.getItem('token');

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Seller: {
        keyFields: ["mantaro_seller_id"],
      },
      Contact: {
        keyFields: ["contact_id"],
      },
      Brand: {
        keyFields: ["mantaro_brand_id"],
      },
      AmazonBrand: {
        keyFields: ["mantaro_brand_id"],
      },
      // AmazonProduct: {
      //     keyFields: ["mantaro_product_id"],
      // },
    },
  }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
