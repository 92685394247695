export const possibleStatuses = [
  "Potential",
  "Outreach",
  "Interested",
  "Maybe Later",
  "Bad Fit",
  "Opt Out",
  "Won",
  "Lost",
  "Failed Deal",
  "Auto-Reply",
];
export const possibleCountries = [
  "DE",
  "US",
  "IT",
  "NL",
  "ES",
  "UK",
  "SE",
  "AU",
  "BR",
  "CN",
  "IN",
  "JP",
  "MX",
  "FR",
];
