import React from "react";
import DetailItem from "../general/DetailItem";
import { Link } from "react-router-dom";

export default function AmazonSellerDetails({
  amazon_seller,
  editMode,
  editableSeller,
  changeHandler,
}) {
  console.log("amazon_seller", amazon_seller);

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold text-mantaropink">Amazon</h2>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: amazon_seller.storefront_url }}
        >
          <button className="button">Storefront</button>
        </Link>
      </div>
      <div className="flex flex-wrap mt-4">
        <DetailItem
          editMode={editMode}
          cssProperty="w-1/3"
          name="AMAZON SELLER ID"
          value={editableSeller}
          changeHandler={changeHandler}
        ></DetailItem>
        <DetailItem
          editMode={editMode}
          cssProperty="w-1/3"
          name="AMAZON SELLER NAME"
          value={editableSeller}
          changeHandler={changeHandler}
        ></DetailItem>
        <DetailItem
          cssProperty="w-1/3"
          name="FOUNDED"
          value={
            amazon_seller.founded ? new Date(amazon_seller.founded).toLocaleDateString() : "unknown"
          }
        ></DetailItem>
        <DetailItem
          cssProperty="w-1/3"
          name="CREATED ON"
          value={new Date(amazon_seller.created_on).toLocaleString()}
        ></DetailItem>
      </div>
      {amazon_seller.marketplaces && amazon_seller.marketplaces.length > 0 ? (
        <>
          <h3 className="text-xl text-mantaropink font-bold">Seller's Marketplaces:</h3>
          {amazon_seller.marketplaces.map((m) => (
            <div className="flex w-full">
              <DetailItem cssProperty="w-1/3" name="MARKETPLACE COUNTRY" value={m.country_code} />
              <DetailItem
                cssProperty="w-1/3"
                name="TOTAL SELLER RATINGS"
                value={m.seller_number_of_ratings}
              />
              <DetailItem
                cssProperty="w-1/3"
                name="AVERAGE SELLER RATING"
                value={m.seller_average_rating}
              />
            </div>
          ))}
        </>
      ) : (
        ""
      )}
    </div>
  );
}
