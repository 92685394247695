import { Route, Switch, Redirect } from "react-router-dom";
import LoginScreen from "./components/general/LoginScreen";

import Sellers from "./components/sellers/Sellers";
import IncompleteSellers from "./components/sellers/IncompleteSellers";
import SellerView from "./components/sellers/SellerView";
import AddSeller from "./components/sellers/AddSeller";
import EditIncompleteSeller from "./components/sellers/EditIncompleteSeller";

import Brands from "./components/brands/Brands";
import BrandView from "./components/brands/BrandView";

import ProductView from "./components/products/ProductView";

import PrivateRoute from "./components/general/PrivateRoute";

import Tinder from "./components/brands/Tinder";

import { isLoggedIn } from "./utils";
import HubspotOverview from "./components/hubspot/HubspotOverview";

export const Routes = () => {
  const baseRedirectURL = isLoggedIn() ? "/sellers/incomplete" : "/login";
  return (
    <div>
      <Switch>
        <Route exact path="/login" component={LoginScreen} />
        <Route exact path="/">
          <Redirect to={baseRedirectURL} />
        </Route>
        <PrivateRoute
          component={Sellers}
          path="/sellers"
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
          exact
        />
        <PrivateRoute
          component={IncompleteSellers}
          path="/sellers/incomplete"
          exact
          allowedRoles={["data-collector", "acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={SellerView}
          path="/sellers/:seller_id"
          exact
          allowedRoles={["acquisitions-specialist", "acquisitions-manager", "data-collector"]}
        />
        <PrivateRoute
          component={AddSeller}
          path="/add/seller"
          exact
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={EditIncompleteSeller}
          path="/edit/seller/:seller_id"
          allowedRoles={["data-collector", "acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={Brands}
          path="/brands"
          exact
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={BrandView}
          path="/brands/:brand_id"
          exact
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={ProductView}
          path="/products/:asin"
          exact
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={Tinder}
          path="/tinder"
          exact
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
        />
        <PrivateRoute
          component={HubspotOverview}
          path="/hubspot-overview"
          allowedRoles={["acquisitions-specialist", "acquisitions-manager"]}
        />
      </Switch>
    </div>
  );
};
