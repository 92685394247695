import React from "react";
import { Link, useHistory } from "react-router-dom";
import { isLoggedIn, logout, getUserName } from "../../utils";
import { gql, useQuery } from "@apollo/client";
import NavigationItem from "./NavigationItem";

const GET_PIPELINE_COUNTS = gql`
  query Pipeline_count {
    pipeline_count {
      tinder_brands
      sellers_incomplete
      not_synced_sellers_to_Hubspot
    }
  }
`;

const userIsLoggedIn = isLoggedIn();
const userName = getUserName();
export default function NavBar() {
  const history = useHistory();
  const { data } = useQuery(GET_PIPELINE_COUNTS);

  return (
    <div className="flex w-full p-8 bg-gray-100 items-center justify-around">
      <div className="flex items-center">
        <p className="text-5xl font-light">TAROX</p>
        <span className="mx-6 font-bold text-4xl text-mantaropink"> x </span>
        <Link to="/sellers">
          <img
            alt="mantaro partners logo"
            src="https://uploads-ssl.webflow.com/60102b4ac2415b85c9f41a6d/6140e2589b1fbc336c8b5d6e_Mantaro%20Logo-p-500.png"
            className="h-16"
          />
        </Link>
      </div>
      {userIsLoggedIn ? (
        <div className="flex">
          <NavigationItem
            name="Brand Tinder"
            href="/tinder"
            sub={data?.pipeline_count.tinder_brands}
          />
          <NavigationItem
            name="Sellers"
            href="/sellers/incomplete"
            sub={data?.pipeline_count.sellers_incomplete}
          />

          <NavigationItem
            name="Hubspot"
            href="/hubspot-overview"
            sub={data?.pipeline_count.not_synced_sellers_to_Hubspot}
          />

          <button
            className="button-reverse"
            onClick={() => {
              logout();
              history.push("/login");
              window.location.reload(false); // force reload so that NavBar disappears
            }}
          >
            Logout {userName}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
