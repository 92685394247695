import React from "react";
import { gql, useQuery } from "@apollo/client";
import LoadingScreen from "../general/LoadingScreen";
import NavBar from "../general/NavBar";
import SellerBox from "../sellers/SellerBox";
import BrandBox from "../brands/BrandBox";
import ProductGeneralBox from "./ProductGeneralBox";
import ProductSalesView from "./ProductSalesView";

const PRODUCT_QUERY = gql`
  query Amazon_product($asin: String!) {
    amazon_product(asin: $asin) {
      asin
      marketplaces {
        asin
        amazon_product_id
        amazon_marketplace_id
        country_code
        name
        currency
        number_of_ratings
        average_rating
        bestseller_rank
        price
        sales_ltm {
          amazon_product_id
          sales {
            value
            time
          }
        }
      }
      seller {
        mantaro_seller_id
        company_name
        isComplete
        amazon_seller {
          amazon_seller_id
        }
      }
      brand {
        mantaro_brand_id
        name
      }
      ltmSalesAllMarketplaces {
        data {
          amazon_product_id
          sales {
            value
            time
          }
        }
      }
    }
  }
`;

export default function ProductView({ match }) {
  const asin = match.params.asin;
  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: {
      asin: asin,
    },
  });

  if (loading) return <LoadingScreen message="Loading product" />;
  if (error) return <p>Error {error.message}</p>;
  const product = data?.amazon_product;

  return (
    <div className="w-full">
      <NavBar />
      <div className="container mt-4">
        <h1 className="p-4 text-2xl font-bold">Product: {product.marketplaces[0].name}</h1>
        <section className="justify-between flex">
          <SellerBox
            mantaro_seller_id={product.seller.mantaro_seller_id}
            company_name={product.seller.company_name}
            marketplace_id={product.marketplaces[0].amazon_marketplace_id}
            amazon_seller_id={product.seller.amazon_seller.amazon_seller_id}
          />
          <BrandBox
            country_code={product.marketplaces[0].country_code}
            amazon_seller_id={product.seller.amazon_seller.amazon_seller_id}
            mantaro_brand_id={product.brand.mantaro_brand_id}
            marketplace_id={product.marketplaces[0].amazon_marketplace_id}
            brand_name={product.brand.name}
          />
        </section>
        <section className="justify-between flex">
          <ProductGeneralBox product={product}></ProductGeneralBox>
        </section>
        <section className="justify-between flex">
          <ProductSalesView product={product}></ProductSalesView>
        </section>
      </div>
    </div>
  );
}
