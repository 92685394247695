import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import EnterSellerDetails from "../sellers/EnterSellerDetails";
import NavBar from "../general/NavBar";
import LoadingScreen from "../general/LoadingScreen";

const GET_SELLER_QUERY = gql`
  query GetIncompleteSeller($seller_id: ID!) {
    seller(id: $seller_id) {
      mantaro_seller_id
      company_name
      address {
        zip_code
        street
        number
        country
        city
        state
      }
      amazon_seller {
        amazon_seller_id
        amazon_seller_name
      }
      contacts {
        contact_id
        first_name
        last_name
        emails {
          email
        }
        phones {
          phone
        }
      }
    }
  }
`;

const EDIT_INCOMPLETE_SELLER = gql`
  mutation CompleteSellerMutation(
    $mantaro_seller_id: ID!
    $company_name: String!
    $address: AddressInput!
    $contact: ContactInput!
    $amazon_seller: AmazonSellerInput!
  ) {
    completeSeller(
      mantaro_seller_id: $mantaro_seller_id
      company_name: $company_name
      address: $address
      contact: $contact
      amazon_seller: $amazon_seller
    )
  }
`;

const CACHE_NEXT_SELLER = gql`
  query GetNextButOneIncompleteSeller {
    getNextButOneIncompleteSeller {
      mantaro_seller_id
      company_name
      created_on
      address {
        zip_code
        street
        number
        country
        city
        state
      }
      contacts {
        contact_id
        first_name
        last_name
        emails {
          email
          type
        }
        phones {
          phone
          type
        }
      }
    }
  }
`;

const SKIP_SELLER = gql`
  mutation Mutation($mantaro_seller_id: ID!) {
    skipSeller(mantaro_seller_id: $mantaro_seller_id)
  }
`;

export default function EditIncompleteSeller({ match }) {
  const seller_id = match.params.seller_id;

  const {
    loading: sellerLoading,
    error: sellerError,
    data: sellerData,
  } = useQuery(GET_SELLER_QUERY, {
    variables: {
      seller_id: seller_id,
    },
  });
  const seller = sellerData?.seller;
  useQuery(CACHE_NEXT_SELLER); // pre-cache next seller

  const [init, setInit] = useState(false);

  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
  });
  const [address, setAddress] = useState({
    country: "",
    state: "",
    city: "",
    zip_code: "",
    street: "",
    number: "",
  });
  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",

    contact_id: 0, // if contact_id is 0, the server will create a new contact

    email_valid: false,
    phone_valid: false,
  });
  const [amazonSeller, setAmazonSeller] = useState({
    is_amazon_seller: false,
    amazon_seller_id: "",
    amazon_seller_name: "",
  });

  const [completeSeller, { loading: completeLoading, data: completeData }] = useMutation(
    EDIT_INCOMPLETE_SELLER,
    {
      variables: {
        mantaro_seller_id: seller_id,
        company_name: companyDetails.company_name,
        address: {
          country: address.country,
          state: address.state,
          city: address.city,
          zip_code: address.zip_code,
          street: address.street,
          number: address.number,
        },
        contact: {
          contact_id: contact.contact_id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          email: contact.email,
          phone: contact.phone,
        },
        amazon_seller: {
          amazon_seller_id: amazonSeller.amazon_seller_id,
          amazon_seller_name: amazonSeller.amazon_seller_name,
        },
      },
    }
  );

  const [skipSeller, { loading: skipLoading, data: skipData }] = useMutation(SKIP_SELLER, {
    variables: {
      mantaro_seller_id: seller_id,
    },
  });
  if (skipLoading) return <LoadingScreen message="skipping seller" />;
  if (skipData && skipData.skipSeller >= 0)
    window.location.href = "/edit/seller/" + skipData.skipSeller;
  if (skipData && skipData.skipSeller === -2)
    alert("Cannot find another incomplete seller (which wasnt skipped)");

  if (completeLoading) return <LoadingScreen message="Submitting seller..." />;
  if (completeData && completeData.completeSeller >= 0)
    window.location.href = "/edit/seller/" + completeData.completeSeller;
  else if (completeData && completeData.completeSeller === -1) {
    alert("Error! Not editing seller as this is a duplicate!");
  } else if (completeData && completeData.completeSeller === -2) {
    window.location.href = "/sellers/incomplete";
    alert("Could not find seller that isnt skipped");
  }

  if (sellerLoading) return <LoadingScreen message="Loading seller..." />;
  if (sellerError) return <p>Error {sellerError.message}</p>;

  if (!init) {
    const fc = seller.contacts[0];
    setCompanyDetails({ company_name: seller.company_name });
    if (seller.address)
      setAddress({
        ...seller.address,
        country: seller.address.country ? seller.address.country : "DE",
      });
    if (seller.contacts.length > 0)
      setContact({
        contact_id: fc.contact_id,
        first_name: fc.first_name,
        last_name: fc.last_name,
        email: fc.emails[0] ? fc.emails[0].email : null,
        phone: fc.phones[0] ? fc.phones[0].phone : null,
      });
    if (seller.amazon_seller?.amazon_seller_id.length > 0) setAmazonSeller(seller.amazon_seller); // wrong?
    setInit(true);
  }

  return (
    <div>
      <NavBar />
      <EnterSellerDetails
        editMode={true} // indicates that this seller is being completed (/edited), not added
        companyDetails={companyDetails}
        setCompanyDetails={setCompanyDetails}
        address={address}
        setAddress={setAddress}
        contact={contact}
        setContact={setContact}
        amazonSeller={amazonSeller}
        setAmazonSeller={setAmazonSeller}
        handleSkip={skipSeller}
        handleSubmit={completeSeller}
      />
    </div>
  );
}
