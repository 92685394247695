import React from "react";
import { generateBrandURL } from "../../utils/urlGenerator";
import { Link } from "react-router-dom";

export default function BrandBox({
  country_code,
  amazon_seller_id,
  mantaro_brand_id,
  marketplace_id,
  brand_name,
}) {
  return (
    <div className="w-5/12 shadow-md hover:shadow-lg duration-150">
      <Link
        target="_blank"
        to={{
          pathname: "/brands/" + mantaro_brand_id,
        }}
      >
        <h3 className="p-4 text-mantaropink uppercase font-bold text-lg">Brand</h3>
        <div className="p-4 pt-1 flex items-center justify-between">
          <p>{brand_name}</p>
          <div className="flex space-x-4">
            <button>
              <Link
                target="_blank"
                rel="noopener noreferrer external"
                to={{
                  pathname: generateBrandURL(
                    country_code,
                    amazon_seller_id,
                    marketplace_id,
                    brand_name
                  ),
                }}
              >
                <img src="../amazon_link.svg" />
              </Link>
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
}
