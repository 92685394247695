import React from "react";
import LoginButton from "./LoginButton";
import NavBar from "./NavBar";
import { isLoggedIn } from "../../utils";
import { useHistory } from "react-router";

function LoginScreen() {
  const history = useHistory();
  if (isLoggedIn()) {
    history.push("/");
  }
  return (
    <div>
      <NavBar />
      <h2 className="text-3xl">Login</h2>
      <LoginButton />
    </div>
  );
}

export default LoginScreen;
