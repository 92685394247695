import React from "react";
import { gql, useMutation } from "@apollo/client";
import DetailItem from "../general/DetailItem";
import LineChart from "../general/LineChart";

const PERSIST_SALES_MUTATION = gql`
  mutation Persist_sales_h10($mantaroSellerId: ID!, $asin: String!, $amazonMarketplaceId: String) {
    persist_sales_h10(
      mantaro_seller_id: $mantaroSellerId
      asin: $asin
      amazon_marketplace_id: $amazonMarketplaceId
    )
  }
`;

export default function ProductSalesView({ product }) {
  const [persistSalesMutation, { loading }] = useMutation(PERSIST_SALES_MUTATION);

  if (loading) return <p>Loading...</p>;

  const getProductSalesOverMonthData = (m) => {
    const mapToMonth = m.sales_ltm.sales.map((x) => ({
      ...x,
      time: new Date(x.time).toLocaleString("default", { month: "long" }),
    }));
    const sumPerMonth = mapToMonth.reduce((acc, cur) => {
      acc[cur.time] = acc[cur.time] + cur.value || cur.value;
      return acc;
    }, {});

    const yPoints = Object.keys(sumPerMonth);
    const xPoints = Object.values(sumPerMonth);

    return {
      labels: yPoints,
      datasets: [
        {
          label: "Product sales",
          data: xPoints,
          fill: false,
          borderColor: "#FD385D", //mantaro pink
          lineTension: 0.4,
          radius: 6,
        },
      ],
    };
  };

  const getProductLTMOfMarketplace = (m) => {
    return m.sales_ltm
      ? Math.round(
          (m.sales_ltm.sales.reduce((n, { value }) => n + value, 0) + Number.EPSILON) * 100
        ) / 100
      : 0;
  };

  const getLastPurchaseDateOfMarketplace = (m) => {
    let lastDate = m.sales_ltm
      ? new Date(m.sales_ltm.sales.reduce((a, b) => (a.time > b.time ? a : b)).time)
      : new Date();
    lastDate = lastDate.toLocaleDateString();
    return lastDate;
  };

  const requestSalesData = (m) => {
    persistSalesMutation({
      variables: {
        mantaroSellerId: product.seller.mantaro_seller_id,
        asin: m.asin,
        amazonMarketplaceId: m.amazon_marketplace_id,
      },
    })
      .then(() => {
        window.location.reload(false);
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div className="shadow-md hover:shadow-lg duration-150 w-full">
      <h3 className="p-4 text-mantaropink uppercase font-bold text-lg">Sales</h3>
      <section className="justify-between w-full">
        {product.marketplaces && product.marketplaces.length > 0 && (
          <>
            <div className="p-4 pt-1 items-center justify-between">
              <h3 className="text-lg text-mantaropink">LTM Sales Over Marketplaces</h3>
            </div>
            {product.marketplaces.map((m) =>
              m.sales_ltm ? (
                <div className="m-4">
                  <div className="flex w-full">
                    <DetailItem
                      cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                      name="MARKETPLACE COUNTRY"
                      value={m.country_code}
                    />
                    <DetailItem
                      cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                      name="REVENUE LTM"
                      value={
                        getProductLTMOfMarketplace(m) +
                        " (as of " +
                        getLastPurchaseDateOfMarketplace(m) +
                        ")"
                      }
                    />
                    <DetailItem
                      cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                      name="CURRENCY"
                      value={m.currency}
                    />
                    <DetailItem
                      cssProperty="w-1/4 p-4 pt-1 items-center justify-between"
                      name="AVERAGE RATING"
                      value={m.average_rating}
                    />
                    <div>
                      <button className="button" onClick={() => requestSalesData(m)}>
                        Update
                      </button>
                    </div>
                  </div>
                  <div>
                    <LineChart data={getProductSalesOverMonthData(m)}></LineChart>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="p-4 pt-1 items-center justify-between">
                    Currently, there are no scraped sales data about this product of the specified
                    marketplace. Data is already on its way, but you can prioritize by clicking the
                    button below.
                  </p>
                  <button className="button" onClick={() => requestSalesData(m)}>
                    Request Sales Data
                  </button>
                </div>
              )
            )}
          </>
        )}
      </section>
    </div>
  );
}
