import React from "react";
import { useHistory } from "react-router-dom";

export default function BrandListItem({ index, brand }) {
  const history = useHistory();
  const handleClickRow = (row) => {
    history.push("/brands/" + brand.mantaro_brand_id);
  };

  return (
    <tr
      onClick={handleClickRow}
      className={index % 2 === 1 ? "cursor-pointer bg-gray-200" : "cursor-pointer"}
    >
      <td className="p-4">{brand.mantaro_brand_id}</td>
      <td className="p-4">{brand.amazon_brand.amazon_brand_name}</td>
      <td className="p-4">{brand.amazon_brand.created_on}</td>
      {/**            <td className="p-4">
                <div className="flex justify-center">
                    <img className="cursor-pointer h-10" src="./export_button.svg" />
                </div>
            </td>
             */}
    </tr>
  );
}
