import React from "react";
import { Link } from "react-router-dom";
import { generatePictureURL } from "../../utils/urlGenerator";

export default function ProductDetails({ product }) {
  return (
    <div>
      <div className="mb-20 flex">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: "https://amazon." + product.country_code + "/dp/" + product.asin }}
        >
          <img alt="" src={generatePictureURL(product.asin, product.country_code)} width="175" />
        </Link>
        <div className="ml-4 w-full">
          <Link target="_blank" rel="noopener noreferrer" to={`/products/${product.asin}`}>
            <h3 className="text-center">{product.name}</h3>
            <div className="flex mt-4 ml-8">
              <div className="label w-1/4">
                Total number of reviews: <span className="kpi">{product.number_of_ratings}</span>
              </div>
              <div className="label w-1/4">
                Average rating: <span className="kpi">{product.average_rating}</span>
              </div>
              <div className="label w-1/4">
                Rank: <span className="kpi">{product.bestseller_rank}</span>
              </div>
            </div>
            <div className="flex mt-4 ml-8">
              <div className="label w-1/3">
                Category:{" "}
                <span className="kpi">{product.translated_category_name.substring(0, 16)}</span>
              </div>
              <div className="label w-1/3">
                Price{" "}
                <span className="kpi">
                  {product.price} {product.currency}
                </span>
              </div>
              <div className="label w-1/3">
                Country code: <span className="kpi">{product.country_code}</span>
              </div>
            </div>
            <div className="flex mt-4 ml-8">
              {product.revenue_ltm && (
                <div className="label">
                  Revenue LTM: <span className="kpi">{product.revenue_ltm.value + " EUR"}</span> (as
                  of {product.revenue_ltm.time})
                </div>
              )}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
