import React, { useState } from "react";
import NavBar from "../general/NavBar";
import { gql, useQuery } from "@apollo/client";
import LeadFilter from "../general/LeadFilter";
import TinderBrand from "./TinderBrand";
import LoadingScreen from "../general/LoadingScreen";

const GET_ALL_TINDER_BRAND_IDS = gql`
  query allTinderBrandIDs {
    getAllTinderBrandIDs
  }
`;

export default function Tinder() {
  const [allBrandIDsArr, setAllBrandIDsArr] = useState(undefined);
  const [filteredBrandIDsArr, setFilteredBrandIDsArr] = useState(undefined);
  const [brandID, setBrandID] = useState(undefined);
  const { data: allBrandIDs, loading: allBrandIDsLoading } = useQuery(GET_ALL_TINDER_BRAND_IDS, {
    onCompleted: () => {
      const arr = allBrandIDs?.getAllTinderBrandIDs;
      setAllBrandIDsArr(arr);
      setFilteredBrandIDsArr(arr);
      const new_brand_id = arr && arr.length > 0 ? arr[Math.floor(Math.random() * arr.length)] : -1;
      setBrandID(new_brand_id);
    },
  });

  if (allBrandIDsLoading) return <LoadingScreen message="Loading tinder brands..." />;

  const handleNext = () => {
    setAllBrandIDsArr(allBrandIDsArr.filter((id) => id !== brandID));
    filterBrands(filteredBrandIDsArr.filter((id) => id !== brandID));
  };

  const filterBrands = (values) => {
    setFilteredBrandIDsArr(values);
    const new_brand_id =
      values && values.length > 0 ? values[Math.floor(Math.random() * values.length)] : -1;
    setBrandID(new_brand_id);
  };

  return (
    <div>
      <NavBar></NavBar>
      <div className="max-w-screen-lg mx-auto">
        <div className="flex my-4 items-center justify-center">
          <LeadFilter filterBrands={filterBrands} allBrandIDsArr={allBrandIDsArr}></LeadFilter>
          <div className="my-4 items-center justify-center">
            After filter:
            <b>
              {" "}
              {filteredBrandIDsArr && filteredBrandIDsArr.length > 0
                ? filteredBrandIDsArr.length
                : 0}
            </b>
          </div>
        </div>

        {brandID !== -1 ? (
          <TinderBrand brand_id={brandID} handleNext={handleNext}></TinderBrand>
        ) : (
          <div>
            <div className="flex my-4 items-center justify-between">
              <h1 className="text-2xl font-bold">No brand found...</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
