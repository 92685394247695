import { gql, useQuery, useMutation } from "@apollo/client";
import React from "react";
import LoadingScreen from "../general/LoadingScreen";
import NavBar from "../general/NavBar";

const GET_SELLERS_HUBSPOT_QUERY = gql(`
  query GetSellersToHubspot {
    getAllSyncedSellersToHubspot {
      mantaro_seller_id
    }
    getNotSyncedSellersToHubspot {
      mantaro_seller_id
    }
    getSellersThatCannotBeSynced {
      mantaro_seller_id
    }
  }
`);

const SYNC_TO_HUBSPOT_MUTATION = gql(`
  mutation Mutation {
    syncSellersToHubspot
  }
`);

export default function HubspotOverview() {
  const { loading, error, data } = useQuery(GET_SELLERS_HUBSPOT_QUERY);
  const [syncToHubspotMutation, { loading: syncLoading }] = useMutation(SYNC_TO_HUBSPOT_MUTATION);

  if (loading) return <LoadingScreen message="Loading..." />;
  if (error) return <p>Error {error.message}</p>;
  if (syncLoading) return <LoadingScreen message="Exporting..." />;

  const allSyncedSellersToHubspot = data.getAllSyncedSellersToHubspot;
  const allNotSyncedSellersToHubspot = data.getNotSyncedSellersToHubspot;
  const allSellersThatCannotBeSynced = data.getSellersThatCannotBeSynced;

  return (
    <div>
      <NavBar />
      <section className="w-10/12 mx-auto flex items-center justify-between my-4 h-12">
        <h1 className="text-3xl font-bold">Hubspot Leads Overview</h1>
      </section>
      <hr className="w-10/12 mx-auto" />
      <div className="flex">
        <table className="lead-table w-10/12 mx-auto">
          <tr>
            <th>Already exported</th>
            <th>Can be exported</th>
            <th>Cannot be exported</th>
          </tr>
          <tr>
            <td>{allSyncedSellersToHubspot.length}</td>
            <td>{allNotSyncedSellersToHubspot.length}</td>
            <td>{allSellersThatCannotBeSynced.length}</td>
          </tr>
        </table>
        <button
          className="button-reverse"
          onClick={() => {
            syncToHubspotMutation();
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
}
