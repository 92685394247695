import React from "react";
import NavBar from "../general/NavBar";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import BrandListItem from "../brands/BrandListItem";

const BRANDS_QUERY = gql`
  {
    brands {
      mantaro_brand_id
      amazon_brand {
        amazon_brand_name
        created_on
      }
    }
  }
`;

export default function Brands() {
  const { loading, error, data } = useQuery(BRANDS_QUERY);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div>
      <NavBar />
      <section className="w-10/12 mx-auto my-4 h-12">
        <div className="flex justify-between w-full">
          <h1 className="text-3xl font-bold">Brands</h1>
          <Link to="/brands/add">
            <img alt="Add Button" src="/add_button.svg" />
          </Link>
        </div>
      </section>
      <div>
        <table className="w-full lead-table">
          <tr className="text-xl font-extralight">
            <th>Mantaro Brand ID</th>
            <th>Amazon Brand Name</th>
            <th>Created On</th>
          </tr>
          {data.brands.map((brand, i) => (
            <BrandListItem brand={brand} index={i} />
          ))}
        </table>
      </div>
    </div>
  );
}
