import { verify } from "jsonwebtoken";

const key = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6sid3oMpT3rfBluskbpP
5XYxoM7SWRl0K06Rb2HYHCTUEHy7wnVoEW+XwZ2bwe+G7OR8lU+YA0pILgtnyPvO
vFpa0+ldZoz5F7cz+1ZgDNBNSTSN4GalphA2Kzp0pk0VMC0uwZl24oJx3Tmfev/e
3lOCXsMT/iVuXrDE/mIKXzAakbJDsyggP7+viwqFZo99zWPrevZ73AFV6J3Uofiz
E51FTVdbiRdIQ1Hd5M1GY4ZMLBWnhY6rTrPB2mNErOxSm2MbdiJ0S1UARWX6nQl3
w1rg2q3TXsDTwU2+XNCLGqNKSYCtK+tYChi35yt3HqemLFtU5rZcSU1LNL+fxAPc
TwIDAQAB
-----END PUBLIC KEY-----
`;

export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  let decoded;
  try {
    decoded = verify(token, key);
  } catch (e) {
    console.log("Could not verify token!");
    return false;
  }
  // if verify does not throw an error, it's a valid token (?)
  console.log(`decoded`, decoded);
  return true;
};

export const getRoles = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  let decoded;
  try {
    decoded = verify(token, key);
  } catch (e) {
    console.log("Could not verify token!");
    return false;
  }
  return decoded.r;
};

export const getUserName = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  let decoded;
  try {
    decoded = verify(token, key);
  } catch (e) {
    console.log("Could not verify token!");
    return false;
  }
  return decoded.sub;
};

export const logout = () => {
  localStorage.removeItem("token");
};
