import React, { useState } from "react";
import { useHistory } from "react-router";
import { isLoggedIn } from "../../utils";

function LoginButton() {
  const [input, setInput] = useState("");
  const history = useHistory();

  const login = () => {
    localStorage.setItem("token", input);
    if (isLoggedIn()) {
      history.push("/sellers");
      window.location.reload(false); // force reload so that NavBar shows up
    } else {
      alert("Invalid credentials!");
    }
  };

  return (
    <div className="flex">
      <textarea
        style={{ cols: 200 }}
        className="border-2 resize"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <button className="button" onClick={login}>
        Submit
      </button>
    </div>
  );
}

export default LoginButton;
