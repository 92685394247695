import React from "react";

export default function LoadingAnimation({ message }) {
  return (
    <div className="flex justify-center items-center">
      <div className="bg-gray-100 p-10 mt-8 rounded-md">
        <img
          src="/loading.gif"
          height="75"
          width="75"
          className="mx-auto my-4"
          alt="loading loading loading gif"
        />
        {message ? <h2 className="text-xl font-bold text-center">{message}</h2> : <p />}
      </div>
    </div>
  );
}
