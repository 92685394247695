import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import NavBar from "../general/NavBar";
import { Link } from "react-router-dom";
import DetailItem from "../general/DetailItem";
import AmazonSellerDetails from "./AmazonSellerDetails";
import ContactsTable from "./ContactsTable";
import BrandsSmallTable from "../brands/BrandsSmallTable";
import LoadingScreen from "../general/LoadingScreen";

export const SELLER_QUERY = gql`
  query GetSeller($seller_id: ID!) {
    seller(id: $seller_id) {
      mantaro_seller_id
      company_name
      created_on
      exported_to_crm
      lead_type
      status
      events {
        event_type
        created_on
        description
      }
      amazon_seller {
        amazon_seller_id
        lo_id
        amazon_seller_name
        amazon_brand {
          mantaro_brand_id
          amazon_brand_name
          created_on
        }
        marketplaces {
          seller_average_rating
          seller_number_of_ratings
          storefront_url
          profile_url
          country_code
          created_on
        }
      }
      brands {
        mantaro_brand_id
        name
        created_on
        platform
      }
      address {
        country
        street
        zip_code
        number
        state
        city
      }
      contacts {
        contact_id
        first_name
        last_name
        emails {
          email
        }
        phones {
          phone
        }
        role
      }
      revenue_ltm {
        time
        value
      }
    }
  }
`;

const editSellerMutation = gql`
  mutation Mutation(
    $mantaro_seller_id: ID!
    $company_name: String!
    $status: String!
    $address: AddressInput!
    $amazon_seller: AmazonSellerInput
    $brands: [BrandInput]
  ) {
    editSeller(
      mantaro_seller_id: $mantaro_seller_id
      company_name: $company_name
      status: $status
      address: $address
      amazon_seller: $amazon_seller
      brands: $brands
    )
  }
`;

export default function SellerView({ match }) {
  const seller_id = match.params.seller_id;
  const { loading, error, data } = useQuery(SELLER_QUERY, {
    variables: {
      seller_id: seller_id,
    },
  });
  let seller = data?.seller;

  const [editMode, setEditMode] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [address, setAddress] = useState({
    country: "DE",
    state: "",
    city: "",
    zip_code: "",
    street: "",
    number: "",
    init: false,
  });
  const [contacts, setContacts] = useState([]);
  const [newAmazonSeller, setNewAmazonSeller] = useState({
    amazon_seller_id: "",
    amazon_seller_name: "",
  });
  const [amazonSeller, setAmazonSeller] = useState({
    amazon_seller_id: "",
    amazon_seller_name: "",
    amazon_seller_lo_id: "",
  });
  const [brands, setBrands] = useState([]);
  const [newBrand, setNewBrand] = useState({ name: "" });
  const [init, setInit] = useState(false);

  const variables = {
    mantaro_seller_id: seller_id,
    company_name: companyName,
    status: leadStatus,
    address: {
      country: address.country,
      state: address.state,
      city: address.city,
      zip_code: address.zip_code,
      street: address.street,
      number: address.number,
    },
    brands: brands,
  };
  if (amazonSeller && amazonSeller.amazon_seller_id && amazonSeller.amazon_seller_id.length > 0)
    variables["amazon_seller"] = amazonSeller;
  const [submitEdit, { data: editData }] = useMutation(editSellerMutation, {
    variables,
    // refetchQueries is not useful here, as most displayed data is in state anyway
  });

  if (editData?.editSeller === "duplicate") {
    alert("Error! Not editing seller as there is a duplicate");
    window.location.reload(true); // forcing reload. Not a pretty solution, but there are no other quick fixes atm.
  }

  if (loading) return <LoadingScreen message="Loading seller..." />;
  if (error) return <p>Error {error.message}</p>;

  if (!init) {
    setCompanyName(seller.company_name);
    setLeadStatus(seller.status);
    // need to get rid of __typename
    // we now need to flatten the contact data as we only want to display one email address
    // setContacts(seller.contacts.map(contact => ({ contact_id: contact.contact_id, first_name: contact.first_name, last_name: contact.last_name, email: contact.emails[0]?.email, phone: contact.phones[0]?.phone })))
    setContacts(
      seller.contacts.map((contact) => ({
        contact_id: contact.contact_id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        emails: contact.emails,
        phone: contact.phones,
      }))
    );

    setAddress({
      country: seller.address?.country ? seller.address.country : "DE",
      state: seller.address?.state,
      city: seller.address?.city,
      zip_code: seller.address?.zip_code,
      street: seller.address?.street,
      number: seller.address?.number,
      init: true,
    });
    setAmazonSeller({
      amazon_seller_id: seller.amazon_seller?.amazon_seller_id,
      amazon_seller_name: seller.amazon_seller?.amazon_seller_name,
      amazon_seller_lo_id: seller.amazon_seller?.lo_id.toString(),
    });
    setBrands(
      seller.brands.map((brand) => ({
        brand_id: brand.mantaro_brand_id,
        name: brand.name,
        platform: brand.platform,
        revenue: brand.revenue_ltm
          ? Math.round((brand.revenue_ltm.value + Number.EPSILON) * 100) / 100
          : 0,
        last_date: brand.revenue_ltm
          ? new Date(brand.revenue_ltm.time).toLocaleDateString()
          : new Date().toLocaleDateString(),
      }))
    );
    setInit(true);
  }

  const checkoutNewAmazonSeller = (e) => {
    if (newAmazonSeller.amazon_seller_id) {
      setAmazonSeller(newAmazonSeller);
    } else console.log("No new amazon seller id");
  };

  const checkoutNewBrand = () => {
    if (newBrand.name.length > 0) {
      setBrands((brand) => [...brands, newBrand]);
    } else {
      console.log("No new brand name");
    }
  };

  const getProductLTMOfSeller = () => {
    return seller.revenue_ltm
      ? Math.round((seller.revenue_ltm.value + Number.EPSILON) * 100) / 100
      : 0;
  };

  const getLastPurchaseDateOfSeller = () => {
    let lastDate = seller.revenue_ltm ? new Date(seller.revenue_ltm.time) : new Date();
    lastDate = lastDate.toLocaleDateString();
    return lastDate;
  };

  return (
    <div className="w-full">
      <NavBar />
      <div className="container">
        <section className="flex items-center justify-between mt-4 h-12">
          <h1 className="text-3xl font-bold">Seller: {companyName}</h1>
          <div>
            {/**/}
            {/*seller.amazonSeller.profile_url ? <Link to={seller.amazon_seller.storefront_url}><button className="bg-mantaropink p-4 font-bold mx-4" style={{ color: '#FFFFFF' }}>Show seller page</button></Link> : "this is not an amazon seller"*/}
            {seller.exported_to_crm ? (
              <Link target="_blank" rel="noopener noreferrer" to={{}}>
                <button className="button">Open in Hubspot</button>
              </Link>
            ) : (
              <button className="button-reverse" onClick={() => exportLead()}>
                Export to Hubspot
              </button>
            )}
          </div>
        </section>
        <hr className="w-full mx-auto" />
        <section>
          <div className="flex justify-between">
            <h2 className="text-2xl font-bold text-mantaropink">General</h2>
            {editMode ? (
              <button
                onClick={() => {
                  checkoutNewAmazonSeller();
                  checkoutNewBrand();
                  setTimeout(() => {
                    setNewBrand({
                      name: "",
                    });
                    setEditMode(false);
                    submitEdit();
                  }, 500);
                }}
                className="button-reverse"
              >
                Submit
              </button>
            ) : (
              <button onClick={() => setEditMode(!editMode)} className="button">
                Edit
              </button>
            )}
          </div>
          <div className="flex flex-wrap mt-4">
            <DetailItem
              editMode={editMode}
              cssProperty="w-1/3"
              name="COMPANY NAME"
              value={companyName}
              changeHandler={setCompanyName}
            ></DetailItem>
            <DetailItem
              editMode={editMode}
              cssProperty="w-1/3"
              name="HEADQUARTER ADDRESS"
              value={address}
              changeHandler={setAddress}
            ></DetailItem>
            <DetailItem
              cssProperty="w-1/3"
              name="CREATED ON"
              value={new Date(seller.created_on).toLocaleString()}
            ></DetailItem>
            <DetailItem
              editMode={editMode}
              cssProperty="w-1/3"
              name="STATUS"
              value={leadStatus}
              changeHandler={setLeadStatus}
            ></DetailItem>
            <DetailItem cssProperty="w-1/3" name="LEAD TYPE" value={seller.lead_type}></DetailItem>
            <DetailItem
              cssProperty="w-1/3"
              name="EXPORTED TO CRM"
              value={
                seller.exported_to_crm
                  ? new Date(seller.exported_to_crm).toLocaleDateString()
                  : "not exported"
              }
            ></DetailItem>
            <DetailItem
              cssProperty="w-1/3"
              name="REVENUE LTM"
              value={
                getProductLTMOfSeller() + " EUR " + " (as of " + getLastPurchaseDateOfSeller() + ")"
              }
            ></DetailItem>
          </div>
          <hr />
          {seller.amazon_seller ? (
            <AmazonSellerDetails
              editMode={editMode}
              amazon_seller={seller.amazon_seller}
              editableSeller={amazonSeller}
              changeHandler={setAmazonSeller}
            />
          ) : editMode ? (
            <div>
              <h3 className="text-xl">Set Amazon Seller here</h3>
              <input
                type="text"
                placeholder="Amazon Seller ID"
                value={newAmazonSeller.amazon_seller_id}
                onChange={(e) =>
                  setNewAmazonSeller({ ...newAmazonSeller, amazon_seller_id: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Amazon Seller Name"
                value={newAmazonSeller.amazon_seller_name}
                onChange={(e) =>
                  setNewAmazonSeller({ ...newAmazonSeller, amazon_seller_name: e.target.value })
                }
              />
            </div>
          ) : (
            "No associated Amazon Seller found"
          )}
        </section>
        <hr />
        {seller.brands ? (
          <BrandsSmallTable
            editMode={editMode}
            setNewBrand={setNewBrand}
            brands={brands}
            setBrands={setBrands}
          />
        ) : (
          "No associated brands found"
        )}
        {seller.contacts ? (
          <ContactsTable contacts={contacts} editMode={editMode} />
        ) : (
          "Could not find associated contacts"
        )}
        <hr />
        <section>
          <div className="flex justify-between mt-2">
            <h2 className="text-2xl font-bold text-mantaropink">Events</h2>
          </div>
          <table className="lead-table">
            <tr>
              <th>Event</th>
              <th>Date</th>
            </tr>
            {seller.events.map((event) => (
              <tr>
                <td>{event.description}</td>
                <td>{new Date(event.created_on).toLocaleString()}</td>
              </tr>
            ))}
          </table>
        </section>
      </div>
    </div>
  );
}
