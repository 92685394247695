import React from "react";
import { possibleStatuses, possibleCountries } from "../../utils/validValues";

export default function DetailItem({ name, value, cssProperty, editMode, changeHandler }) {
  if (name === "STATUS") {
    return (
      <div className={cssProperty + " pb-8"}>
        <span className="text-mantaropink">{name.toString().toUpperCase()}</span>
        <br />
        {editMode === true ? (
          <select name="STATUS" value={value} onChange={(e) => changeHandler(e.target.value)}>
            {possibleStatuses.map((status) => (
              <option value={status}>{status}</option>
            ))}
          </select>
        ) : (
          value
        )}
      </div>
    );
  }

  if (name === "OPT OUT") {
    return (
      <div className={cssProperty + " pb-8"}>
        <span className="text-mantaropink">{name.toString().toUpperCase()}</span>
        <br />
        {editMode === true ? (
          <select name="OPT OUT" value={value} onChange={(e) => changeHandler(e.target.value)}>
            <option value="false">false</option>
            <option value="true">true</option>
          </select>
        ) : (
          value
        )}
      </div>
    );
  }

  if (name === "HEADQUARTER ADDRESS") {
    return (
      <div className={cssProperty + " pb-8"}>
        <span className="text-mantaropink">{name.toString().toUpperCase()}</span>
        <br />
        {editMode === true ? (
          <div>
            <select
              value={value.country}
              onChange={(e) => changeHandler({ ...value, country: e.target.value })}
            >
              {possibleCountries.map((country) =>
                country === "DE" ? (
                  <option selected value={country}>
                    {country}
                  </option>
                ) : (
                  <option value={country}>{country}</option>
                )
              )}
            </select>
            <input
              placeholder="state"
              type="text"
              name="state"
              value={value.state}
              onChange={(e) => changeHandler({ ...value, state: e.target.value })}
            />
            <input
              placeholder="city"
              type="text"
              name="city"
              value={value.city}
              onChange={(e) => changeHandler({ ...value, city: e.target.value })}
            />
            <input
              placeholder="zip_code"
              type="text"
              name="zip_code"
              value={value.zip_code}
              onChange={(e) => changeHandler({ ...value, zip_code: e.target.value })}
            />
            <input
              placeholder="street"
              type="text"
              name="street"
              value={value.street}
              onChange={(e) => changeHandler({ ...value, street: e.target.value })}
            />
            <input
              placeholder="number"
              type="text"
              name="number"
              value={value.number}
              onChange={(e) => changeHandler({ ...value, number: e.target.value })}
            />
          </div>
        ) : (
          <div>
            {value.city}
            <br />
            {value.street + " " + value.number}
          </div>
        )}
      </div>
    );
  }

  if (name === "AMAZON SELLER ID") {
    return (
      <div className={cssProperty + " pb-8"}>
        <span className="text-mantaropink">{name.toString().toUpperCase()}</span>
        <br />
        {editMode === true ? (
          <input
            type="text"
            name="amazon_seller_id"
            value={value.amazon_seller_id}
            onChange={(e) => changeHandler({ ...value, amazon_seller_id: e.target.value })}
          />
        ) : (
          <div>{value.amazon_seller_id}</div>
        )}
      </div>
    );
  }

  if (name === "AMAZON SELLER NAME") {
    return (
      <div className={cssProperty + " pb-8"}>
        <span className="text-mantaropink">{name.toString().toUpperCase()}</span>
        <br />
        {editMode === true ? (
          <input
            type="text"
            name="amazon_seller_name"
            value={value.amazon_seller_name}
            onChange={(e) => changeHandler({ ...value, amazon_seller_name: e.target.value })}
          />
        ) : (
          <div>{value.amazon_seller_name}</div>
        )}
      </div>
    );
  }

  return (
    <div className={cssProperty + " pb-8"}>
      <span className="text-mantaropink">{name.toString().toUpperCase()}</span>
      <br />
      {editMode === true ? (
        <input
          type="text"
          value={value}
          onChange={(event) => {
            changeHandler(event.target.value);
          }}
        />
      ) : (
        value
      )}
    </div>
  );
}
