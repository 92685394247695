import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getRoles } from "../../utils";
// import { getUser } from '../../../../backend/lead-orchestrator/user'

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const roleValid = () => {
    if (!allowedRoles || allowedRoles.length === 0) return true;
    const roles = getRoles();
    if (!roles || roles.length === 0) return false;
    console.log("roles", roles);
    console.log("allowedRoles", allowedRoles);
    for (const role of roles) {
      if (role === "admin") return true;
      if (allowedRoles.includes(role)) return true;
    }
    return false;
  };
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => (roleValid() ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
