import React from "react";
import { useHistory } from "react-router-dom";

function SellerListItem({ index, seller, incomplete }) {
  const history = useHistory();
  const handleClickRow = (row) => {
    if (incomplete) {
      history.push("/edit/seller/" + seller.mantaro_seller_id);
    } else {
      history.push("/sellers/" + seller.mantaro_seller_id);
    }
  };

  return (
    <tr
      className={
        "hover:bg-gray-100 duration-300 " +
        (index % 2 === 1 ? "cursor-pointer bg-gray-200" : "cursor-pointer")
      }
    >
      <td onClick={handleClickRow} className="p-4">
        {seller.mantaro_seller_id}
      </td>
      <td onClick={handleClickRow} className="p-4">
        {seller.company_name}
      </td>
      <td onClick={handleClickRow} className="p-4">
        {new Date(seller.created_on).toLocaleDateString()}
      </td>
    </tr>
  );
}

export default SellerListItem;
