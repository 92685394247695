import React, { useState } from "react";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { possibleStatuses, possibleCountries } from "../../utils/validValues";
import { Link } from "react-router-dom";

const validateEmail = (email) => {
  if (!email || email.length === 0) return false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = re.test(String(email).toLowerCase());
  return result;
};

const validatePhone = (phone) => {
  if (!phone || phone.length === 0) return false;
  return isPossiblePhoneNumber(phone, "DE"); // second argument is only default country, not necessary
};

const validateNormalField = (s) => {
  if (s && s.length > 0) return true;
  else return false;
};
const getBorderColor = (valid) => (valid ? " border-green-400" : " border-red-400");

let existingAmazonSeller = false;

export default function EnterSellerDetails({
  editMode,
  companyDetails,
  setCompanyDetails,
  address,
  setAddress,
  contact,
  setContact,
  amazonSeller,
  setAmazonSeller,
  brands,
  setBrands,
  handleSkip,
  handleSubmit,
}) {
  const [fieldValidator, setFieldValidator] = useState({
    company_name: false,
    country: false,
    city: false,
    zip_code: false,
    street: false,
    number: false,
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    amazon_seller_id: false,
  });
  const [init, setInit] = useState(false);
  const validatePhoneAndEmail = () => {
    // if an email or phone is entered, only allow it to be valid
    if (contact.phone && contact.phone.length > 0) {
      if (!validatePhone(contact.phone)) return false;
    }
    if (contact.email && contact.email.length > 0) {
      if (!validateEmail(contact.email)) return false;
    }
    return true;
  };
  const checkRequiredFields = () => {
    let response = "nothing missing";
    for (const [key, value] of Object.entries(fieldValidator)) {
      console.log(`key, value`, key, value);
      if (!value) {
        // may be equivalent to fieldValidator[key] !== true
        if (key === "last_name" && fieldValidator.first_name)
          response = "missing un-required fields";
        else if (key === "email" && fieldValidator.phone) response = "missing un-required fields";
        else if (key === "phone" && fieldValidator.email) response = "missing un-required fields";
        else {
          response = "missing required fields";
          return response;
        }
      }
    }
    return response;
  };
  if (!init) {
    setContact({
      ...contact,
      email_valid: validateEmail(contact.email),
      phone_valid: validatePhone(contact.phone),
    });
    setFieldValidator({
      company_name: validateNormalField(companyDetails.company_name),
      country: true,
      city: validateNormalField(address.city),
      zip_code: validateNormalField(address.zip_code),
      street: validateNormalField(address.street),
      number: validateNormalField(address.number),
      first_name: validateNormalField(contact.first_name),
      last_name: validateNormalField(contact.last_name),
      email: validateEmail(contact.email),
      phone: validatePhone(contact.phone),
      amazon_seller_id: amazonSeller.amazon_seller_id ? true : false,
    });
    existingAmazonSeller =
      !!amazonSeller.amazon_seller_id && amazonSeller.amazon_seller_id.length > 0 ? true : false;

    setInit(true);
  }

  return (
    <div>
      <div>
        <div className="container">
          <section className="flex items-center justify-between mt-4 h-12">
            <h1 className="text-3xl font-bold">
              {editMode ? "Edit a seller: " : "Add a new seller: "}
              {companyDetails.company_name}
            </h1>
            {editMode && existingAmazonSeller ? (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: "https://www.amazon.de/sp?seller=" + amazonSeller.amazon_seller_id,
                }}
              >
                <button className="button">Go to Amazon page</button>
              </Link>
            ) : (
              ""
            )}
          </section>
          <hr />
          <section className="inputs-padding">
            <h2 className="text-2xl font-bold text-mantaropink">General</h2>
            <div>
              {" "}
              {/* all entries*/}
              <div className="pb-8 flex">
                {" "}
                {/* first row */}
                <div className="w-2/5">
                  <span className="text-mantaropink">COMPANY NAME</span>
                  <br />
                  <input
                    type="text"
                    className={"w-11/12" + getBorderColor(fieldValidator.company_name)}
                    value={companyDetails.company_name}
                    onChange={(e) => {
                      setCompanyDetails({ ...companyDetails, company_name: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        company_name: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
                {!editMode ? (
                  <div className="w-1/5">
                    <span className="text-mantaropink">STATUS</span>
                    <br />
                    <select
                      value={companyDetails.status}
                      onChange={(e) =>
                        setCompanyDetails({ ...companyDetails, status: e.target.value })
                      }
                    >
                      {possibleStatuses.map((status) => (
                        <option value={status}>{status}</option>
                      ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {!editMode ? (
                  <div className="w-1/5">
                    <span className="text-mantaropink">LEAD TYPE</span>
                    <br />
                    <select
                      value={companyDetails.lead_type}
                      onChange={(e) =>
                        setCompanyDetails({ ...companyDetails, lead_type: e.target.value })
                      }
                    >
                      <option value="personal">personal</option>
                      <option value="inbound">inbound</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex">
                <div className="w-1/12">
                  <span className="text-mantaropink">HQ COUNTRY</span>
                  <br />
                  <select
                    value={address.country}
                    onChange={(e) => {
                      setAddress({ ...address, country: e.target.value });
                    }}
                  >
                    {possibleCountries.map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                </div>
                <div className="w-1/5">
                  <span className="text-mantaropink">HQ STATE</span>
                  <br />
                  <input
                    type="text"
                    value={address.state}
                    onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  />
                </div>
                <div className="w-1/5">
                  <span className="text-mantaropink">HQ CITY</span>
                  <br />
                  <input
                    className={getBorderColor(fieldValidator.city)}
                    type="text"
                    value={address.city}
                    onChange={(e) => {
                      setAddress({ ...address, city: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        city: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
                <div className="w-1/5">
                  <span className="text-mantaropink">HQ ZIPCODE</span>
                  <br />
                  <input
                    className={getBorderColor(fieldValidator.zip_code)}
                    type="text"
                    value={address.zip_code}
                    onChange={(e) => {
                      if (e.target.value.length > 10) return;
                      setAddress({ ...address, zip_code: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        zip_code: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
                <div className="w-1/5">
                  <span className="text-mantaropink">HQ STREET</span>
                  <br />
                  <input
                    className={getBorderColor(fieldValidator.street)}
                    type="text"
                    value={address.street}
                    onChange={(e) => {
                      setAddress({ ...address, street: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        street: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
                <div className="w-1/5">
                  <span className="text-mantaropink">HQ STREET NUMBER</span>
                  <br />
                  <input
                    className={getBorderColor(fieldValidator.number)}
                    type="text"
                    value={address.number}
                    onChange={(e) => {
                      setAddress({ ...address, number: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        number: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <hr />
          <section className="inputs-padding">
            <h2 className="text-mantaropink text-3xl font-bold">Contact</h2>
            <div>
              <div className="flex">
                <div className="w-1/2">
                  <span className="text-mantaropink">CONTACT ID</span>
                  <br />
                  {contact.contact_id}
                </div>
                <div className="w-1/2">
                  <span className="text-mantaropink">FIRST NAME</span>
                  <br />
                  <input
                    className={"w-10/12" + getBorderColor(fieldValidator.first_name)}
                    type="text"
                    value={contact.first_name}
                    onChange={(e) => {
                      setContact({ ...contact, first_name: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        first_name: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <span className="text-mantaropink">LAST NAME</span>
                  <br />
                  <input
                    className={"w-10/12" + getBorderColor(fieldValidator.last_name)}
                    type="text"
                    value={contact.last_name}
                    onChange={(e) => {
                      setContact({ ...contact, last_name: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        last_name: validateNormalField(e.target.value),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex mt-4">
                <div className="w-1/2">
                  <span className="text-mantaropink">EMAIL</span>
                  <br />
                  <input
                    className={"w-10/12" + getBorderColor(fieldValidator.email)}
                    type="text"
                    value={contact.email}
                    onChange={(e) => {
                      setContact({ ...contact, email: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        email: validateEmail(e.target.value),
                      });
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <span className="text-mantaropink">PHONE</span>
                  <br />
                  <input
                    className={"w-10/12" + getBorderColor(fieldValidator.phone)}
                    type="text"
                    value={contact.phone}
                    onChange={(e) => {
                      setContact({ ...contact, phone: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        phone: validatePhone(e.target.value),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <hr />
          <section className="inputs-padding">
            <div className="flex items-center">
              <h2 className="text-mantaropink text-3xl font-bold mr-4">Amazon Seller</h2>
            </div>
            <div className="flex">
              <div>
                <span className="text-mantaropink">AMAZON SELLER ID</span>
                <br />
                {editMode && existingAmazonSeller ? (
                  <p>{amazonSeller.amazon_seller_id}</p>
                ) : (
                  <input
                    className={getBorderColor(fieldValidator.amazon_seller_id)}
                    type="text"
                    value={amazonSeller.amazon_seller_id}
                    onChange={(e) => {
                      setAmazonSeller({ ...amazonSeller, amazon_seller_id: e.target.value });
                      setFieldValidator({
                        ...fieldValidator,
                        amazon_seller_id: validateNormalField(e.target.value),
                      });
                    }}
                  />
                )}
              </div>
              <div className="ml-8">
                <span className="text-mantaropink">AMAZON SELLER NAME</span>
                <br />
                {editMode && existingAmazonSeller ? (
                  <p>{amazonSeller.amazon_seller_name}</p>
                ) : (
                  <input
                    type="text"
                    value={amazonSeller.amazon_seller_name}
                    onChange={(e) =>
                      setAmazonSeller({ ...amazonSeller, amazon_seller_name: e.target.value })
                    }
                  />
                )}
              </div>
            </div>
          </section>
          <hr />
          {!editMode ? (
            <section className="inputs-padding">
              <div className="items-center">
                <div className="flex">
                  <h2 className="text-mantaropink text-3xl font-bold mr-4">Brands</h2>
                  <button className="button" onClick={() => setBrands([...brands, { name: "" }])}>
                    Add a new brand
                  </button>
                </div>
                <div>
                  {brands.map((brand, index) => (
                    <input
                      type="text"
                      placeholder="new brand name"
                      value={brand.name}
                      onChange={(e) => {
                        let new_brands = [...brands];
                        new_brands[index] = { name: e.target.value, brand_id: brand.brand_id };
                        setBrands(new_brands);
                      }}
                    />
                  ))}
                </div>
              </div>
              {/* <div>
                            <span className="text-mantaropink">BRAND #1 NAME</span><br />
                            <input type="text" value={amazonSeller.amazon_seller_id} onChange={e => setAmazonSeller({ ...amazonSeller, amazon_seller_id: e.target.value })} />
                        </div> */}
            </section>
          ) : (
            ""
          )}
          <hr />
          <div className="justify-end">
            {editMode ? (
              <div>
                <button
                  className="button"
                  onClick={() => {
                    if (!validatePhoneAndEmail()) {
                      console.log("");
                      alert("Email or phone is invalid. Please enter valid data.");
                      return;
                    }
                    switch (checkRequiredFields()) {
                      case "nothing missing":
                        handleSubmit();
                        return;
                      case "missing un-required fields":
                        // alert("You're missing fields that aren't required. Are you sure you want to continue?")
                        if (
                          window.confirm(
                            "You're missing fields that are not necessarily required. Are you sure you want to continue?"
                          )
                        )
                          return handleSubmit();
                        else return;
                      case "missing required fields":
                        alert("You have not entered enough data. Please enter more data.");
                        return;
                      default:
                        console.log("WEIRD AF!");
                    }
                  }}
                >
                  Submit and go to next incomplete seller
                </button>
                <button
                  className="button-reverse"
                  onClick={() => {
                    handleSkip();
                  }}
                >
                  Skip
                </button>
              </div>
            ) : (
              <button
                className="button"
                onClick={() => {
                  if (!validatePhoneAndEmail()) {
                    alert("Email or phone is invalid. Please enter valid data.");
                    return;
                  }
                  switch (checkRequiredFields()) {
                    case "nothing missing":
                      handleSubmit();
                      return;
                    case "missing un-required fields":
                      // alert("You're missing fields that aren't required. Are you sure you want to continue?")
                      if (
                        window.confirm(
                          "You're missing fields that are not necessarily required. Are you sure you want to continue?"
                        )
                      )
                        return handleSubmit();
                      else return;
                    case "missing required fields":
                      alert("You have not entered enough data. Please enter more data.");
                      return;
                    default:
                      console.log("WEIRD AF!");
                  }
                }}
              >
                Submit!
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
