import React from "react";
import LoadingAnimation from "./LoadingAnimation";
import NavBar from "./NavBar";

export default function LoadingScreen({ message }) {
  return (
    <div>
      <NavBar />
      <LoadingAnimation message={message} />
    </div>
  );
}
