import React, { useState, useEffect } from "react";
import NavBar from "../general/NavBar";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import SellerListItem from "./SellerListItem";
import LoadingScreen from "../general/LoadingScreen";
import LoadingAnimation from "../general/LoadingAnimation";
import InfiniteScroll from "react-infinite-scroll-component";

const NEXT_BATCH_INCOMPLETE_SELLERS = gql`
  query GetNextBatchIncompleteSellers($offset: Int) {
    sellers(only_incomplete: true, offset: $offset) {
      mantaro_seller_id
      company_name
      created_on
    }
  }
`;

const batchSize = 50;

export default function IncompleteSellers() {
  const [init, setInit] = useState(false);
  const [sellers, setSellers] = useState([]);

  let [fetchNextBulk, { data: nextSellersBatch }] = useLazyQuery(NEXT_BATCH_INCOMPLETE_SELLERS);
  useEffect(() => {
    console.log("useEffect called");
    if (nextSellersBatch && nextSellersBatch.sellers) {
      setSellers(sellers.concat(nextSellersBatch.sellers));
      setLastSellerRank(lastSellerRank + batchSize);
      nextSellersBatch = null;
    }
  }, [nextSellersBatch]);

  const [lastSellerRank, setLastSellerRank] = useState(0);

  const { data, loading } = useQuery(NEXT_BATCH_INCOMPLETE_SELLERS, { variables: { offset: 0 } });
  if (!init && !loading && data) {
    console.log("Setting initial data");
    setSellers(data.sellers);
    setLastSellerRank(batchSize);
    setInit(true);
  }

  const loadNextSellers = () => {
    fetchNextBulk({ variables: { offset: lastSellerRank } });
  };

  if (loading) return <LoadingScreen />;
  if (!init) {
    setInit(true);
  }
  return (
    <div>
      <NavBar />
      <section className="w-10/12 mx-auto my-4 h-12">
        <h1 className="text-3xl font-bold">Showing all incomplete sellers</h1>
      </section>
      <hr className="my-2 text-opacity-30 w-10/12 mx-auto" />
      <div>
        <table className="w-full lead-table">
          <InfiniteScroll
            dataLength={sellers.length + 1}
            next={loadNextSellers}
            hasMore={true}
            loader={<LoadingAnimation />}
            className="w-full table"
          >
            <tr className="text-xl font-extralight w-full">
              <th>Mantaro Seller ID</th>
              <th>Name</th>
              <th>Created On</th>
            </tr>
            {sellers?.map((seller, i) => (
              <SellerListItem incomplete={true} seller={seller} index={i} />
            ))}
          </InfiniteScroll>
        </table>
      </div>
    </div>
  );
}
