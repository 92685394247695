import React from "react";
import { generateSellerURL } from "../../utils/urlGenerator";
import { Link } from "react-router-dom";

export default function SellerBox({
  mantaro_seller_id,
  company_name,
  marketplace_id,
  amazon_seller_id,
}) {
  return (
    <div className="w-5/12 shadow-md hover:shadow-lg duration-150">
      <Link
        target="_blank"
        to={{
          pathname: "/sellers/" + mantaro_seller_id,
        }}
      >
        <h3 className="p-4 text-mantaropink uppercase font-bold text-lg">Seller</h3>
        <div className="p-4 pt-1 flex items-center justify-between">
          <p>{company_name}</p>
          <div className="flex space-x-4">
            <button>
              <Link
                target="_blank"
                rel="noopener noreferrer external"
                to={{
                  pathname: generateSellerURL(marketplace_id, amazon_seller_id),
                }}
              >
                <img src="../amazon_link.svg" />
              </Link>
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
}
